import { BehaviorSubject } from "rxjs";
import { ElementTypes } from "../enums/ElementTypes";
import { LanguageService } from "src/app/translate/language.service";

export type TEnableFeaturesSubject = {
    key: string,
    newValue?: boolean
}

export type TEnableFeaturesGrouped = {
    elements: { id: string, is_active: boolean, label: string }[],
    tools: { id: string, is_active: boolean, label: string }[],
    marketing: { id: string, is_active: boolean, label: string }[]
}

export type TEnableFeatures = {
    element_multicondition?: boolean;
    element_splitter?: boolean;
    element_form_extended?: boolean;
    smart_balancer?: boolean;
    chats?: boolean;
    version?: boolean;
    sharing?: boolean;
    element_whatsapp?: boolean;
    element_sensei_mailer?: boolean;
    branch_analytics?: boolean ;
    dynamic_segments?: string;
    marketplace?: boolean;
    workplaces?: boolean;
    element_if?: boolean;
    element_task?: boolean;
    element_wait?: boolean;
    element_smsc?: boolean;
    element_mail?: boolean;
    element_status?: boolean;
    element_responsible?: boolean;
    element_webhook?: boolean;
    element_lead?: boolean;
    element_tag?: boolean;
    element_process?: boolean;
    element_field?: boolean;
    element_sipuni?: boolean;
    element_form?: boolean;
    element_getdoc?: boolean;
    element_note?: boolean;
    element_smsru?: boolean;
    element_hyperscript?: boolean;
    element_assistant?: boolean;
    element_queueuser?: boolean;
    element_transition?: boolean;
    element_mandrill?: boolean;
    element_salesbot?: boolean;
    element_telegrambot?: boolean;
    element_js?: boolean;
    element_css?: boolean;
    element_b2bfamily?: boolean;
    element_privatewidget?: boolean;
    element_limiter?: boolean;
    private_cloud?: boolean;
    planner?: boolean;
    planner_automation?: boolean;
    element_whatsapp_conditions?: boolean;
}

export interface IEnableFeatures extends TEnableFeatures {
    setEnableFeatures(enableFeatures: TEnableFeatures): EnableFeatures;
    checkIsEnableElements(elementType: string): boolean;
    get(type: keyof TEnableFeatures): boolean;
    groupedFeatures(): TEnableFeaturesGrouped;
}

export class EnableFeatures implements IEnableFeatures {
    element_multicondition: boolean = false;
    element_splitter: boolean = true;
    element_form_extended: boolean = false;
    smart_balancer: boolean = false;
    chats: boolean = false;
    version: boolean = false;
    sharing: boolean = false;
    element_whatsapp: boolean = true;
    element_sensei_mailer: boolean = true;
    branch_analytics: boolean = true;
    dynamic_segments: string = "2";
    marketplace: boolean = true;
    workplaces: boolean = true;
    element_if: boolean = false;
    element_task: boolean = false;
    element_wait: boolean = false;
    element_smsc: boolean = false;
    element_mail: boolean = false;
    element_status: boolean = false;
    element_responsible: boolean = false;
    element_webhook: boolean = false;
    element_lead: boolean = false;
    element_tag: boolean = false;
    element_process: boolean = false;
    element_field: boolean = false;
    element_sipuni: boolean = false;
    element_form: boolean = false;
    element_getdoc: boolean = false;
    element_note: boolean = false;
    element_smsru: boolean = false;
    element_hyperscript: boolean = false;
    element_assistant: boolean = false;
    element_queueuser: boolean = false;
    planner: boolean = false;
    element_transition: boolean = false;
    element_mandrill: boolean = false;
    element_salesbot: boolean = false;
    element_telegrambot: boolean = false;
    element_js: boolean = false;
    element_css: boolean = false;
    element_b2bfamily: boolean = false;
    element_privatewidget: boolean = false;
    element_limiter: boolean = false;
    private_cloud: boolean = true;
    process_start_link_parameters: boolean = false;
    planner_automation: boolean = false;
    element_whatsapp_conditions: boolean = true;

    changesEnableFeatures: BehaviorSubject<TEnableFeaturesSubject>;

    constructor(changesEnableFeatures: BehaviorSubject<TEnableFeaturesSubject>) {
        this.changesEnableFeatures = changesEnableFeatures
    }

    get(type: keyof TEnableFeatures): boolean {
        return !!this[type];
    }

    setEnableFeatures(enableFeatures: TEnableFeatures): EnableFeatures {
        let objNames = {
            'process_versions': 'version',
            'process_comments': 'chats',
            'process_share_link': 'sharing',
            'planner/automation': 'planner_automation'
        }
        let isChanged: boolean = false;
        for (let name in enableFeatures) {
            if (name === 'smart_balancer' && this.smart_balancer !== enableFeatures[name]) {
                isChanged = this.changeEnableFeatures(name, enableFeatures[name]) || isChanged;
                this.changesEnableFeatures.next({key: name, newValue: enableFeatures[name]});
            } else {
                isChanged = this.changeEnableFeatures(((objNames[name] !== undefined) ? objNames[name] : name), enableFeatures[name]) || isChanged;
            }
        }

        isChanged && this.changesEnableFeatures.next({key: 'all'});
        return this;
    }

    private changeEnableFeatures(type: string, enableFeatures: boolean): boolean {
        if (enableFeatures !== undefined && this[type] !== enableFeatures) {
            this[type] = enableFeatures;
            return true;
        }
        return false;
    }

    groupedFeatures(): TEnableFeaturesGrouped {
        let groupedFeatures: TEnableFeaturesGrouped = {
            tools: [],
            marketing: [],
            elements: []
        }
        for (const features in this) {
            if (typeof this[features] === 'object') {
                continue;
            }
            if (features.indexOf('element_') !== -1 || features === 'private_cloud') {
                if (features === 'element_form_extended') {
                    groupedFeatures.elements.push({ id: features, is_active: !!this[features], label: LanguageService.translate('constructor.elements.script.title') });
                } else if (features === 'element_sensei_mailer') {
                    groupedFeatures.elements.push({ id: features, is_active: !!this[features], label: LanguageService.translate(`constructor.elements.senseimailer.title`) });
                } else if (features === 'private_cloud') {
                    groupedFeatures.elements.push({ id: features, is_active: !!this[features], label: LanguageService.translate(`back.features.names.${features}`) })
                } else if (features.replace('element_', '')) {
                    groupedFeatures.elements.push({
                        id: features, is_active: !!this[features],
                        label: LanguageService.translate(`constructor.elements.${features.replace('element_', '').replace('_', '')}.title`)
                    });
                }
            } else if (this.checkIsTool(features)) {
                groupedFeatures.tools.push({ id: features, is_active: !!this[features], label: LanguageService.translate(`billing.sensei_tariff.table_features.features.tools.${features}`) });
            } else if (this.checkIsMarketing(features)) {
                if (features === 'process_start_link_parameters') {
                    groupedFeatures.marketing.push({
                            id: 'cascade_mailings',
                            is_active: this.dynamic_segments === '2',
                            label: LanguageService.translate(`billing.sensei_tariff.table_features.features.marketing.cascade_mailings`),
                        },
                        {
                            id: 'whatsapp_mailings',
                            is_active: this.element_whatsapp,
                            label: LanguageService.translate(`billing.sensei_tariff.table_features.features.marketing.whatsapp_mailings`),
                        },
                        {
                            id: 'email_mailings',
                            is_active: this.element_mail,
                            label: LanguageService.translate(`billing.sensei_tariff.table_features.features.marketing.email_mailings`)
                        },
                        {
                            id: 'analytic_mailings',
                            is_active: this.element_whatsapp || this.element_mail,
                            label: LanguageService.translate(`billing.sensei_tariff.table_features.features.marketing.analytic_mailings`),
                        })
                }
            }
        }
        return groupedFeatures;
    }

    private checkIsTool(elementName: string): boolean {
        switch (elementName) {
            case 'workplaces':
                return true;
            case 'planner':
                return true;
            case 'chats':
                return true;
            case 'sharing':
                return true;
            case 'smart_balancer':
                return true;
            case 'planner_automation':
                return true;
            default:
                return false;
        }
    }
    checkIsMarketing(elementName: string): boolean {
        switch (elementName) {
            case 'process_start_link_parameters':
                return true;
            case 'element_splitter':
                return true;
            default:
                return false;
        }
    }
    checkIsEnableElements(elementType: string): boolean {
        switch(elementType) {
            case ElementTypes.START:
                return false;
                break;
            case ElementTypes.NOTE:
                return this.element_note;
                break;
            case ElementTypes.JS:
                return this.element_js;
                break;
            case ElementTypes.CSS:
                return this.element_css;
                break;
            case ElementTypes.TASK:
                return this.element_task;
                break;
            case ElementTypes.WAIT:
                return this.element_wait;
                break;
            case ElementTypes.FORM:
                return this.element_form;
                break;
            case ElementTypes.FIELD:
                return this.element_field;
                break;
            case ElementTypes.IF:
                return this.element_if;
                break;
            case ElementTypes.WEBHOOK:
                return this.element_webhook;
                break;
            case ElementTypes.PROCESS:
                return this.element_process;
                break;
            case ElementTypes.TAG:
                return this.element_tag;
                break;
            case ElementTypes.RESPONSIBLE:
                return this.element_responsible;
                break;
            case ElementTypes.STATUS:
                return this.element_status;
                break;
            case ElementTypes.LEAD:
                return this.element_lead;
                break;
            case ElementTypes.ASSISTANT:
                return this.element_assistant;
                break;
            case ElementTypes.SCRIPT:
                return this.element_form_extended;
                break;
            case ElementTypes.QUEUE:
                return this.element_queueuser;
                break;
            case ElementTypes.SMSRU:
                return this.element_smsru;
                break;
            case ElementTypes.SMSCENTER:
                return this.element_smsc;
                break;
            case ElementTypes.GET_DOC:
                return this.element_getdoc;
                break;
            case ElementTypes.SIPUNI:
                return this.element_sipuni;
                break;
            case ElementTypes.SALESBOT:
                return this.element_salesbot;
                break;
            case ElementTypes.MAIL:
                return this.element_mail;
                break;
            case ElementTypes.TELEGRAMBOT:
                return this.element_telegrambot;
                break;
            case ElementTypes.HYPERSCRIPT:
                return this.element_hyperscript;
                break;
            case ElementTypes.MANDRILL:
                return this.element_mandrill;
                break;
            case ElementTypes.B2BFAMILY:
                return this.element_b2bfamily;
                break;
            case ElementTypes.SPLITTER:
                return this.element_splitter;
                break;
            case ElementTypes.MULTICONDITION:
                return this.element_multicondition;
                break;
            case ElementTypes.LIMITER:
                return this.element_limiter;
                break;
            case ElementTypes.WABABA:
                return this.element_whatsapp;
                break;
            case ElementTypes.SENSEI_MAILER:
                return this.element_sensei_mailer;
                break;
            default:
                return true;
                break;
        }
    }
}
