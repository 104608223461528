import { Injectable } from '@angular/core';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PostMessageService {
    private subjectPostMessageEvent = new Subject<MessageEvent>();

    constructor() { }

    enablePostMessageEvent(): Subscription {
        return fromEvent(window, 'message').subscribe((e: MessageEvent) => {
            this.subjectPostMessageEvent.next(e);
        });
    }

    getPostMessageEvent(): Observable<MessageEvent> {
        return this.subjectPostMessageEvent.asObservable();
    }
}
