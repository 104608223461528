import { TLangObject } from "src/app/translate/lang.types";

export const langData: TLangObject = {
    "ru": {
        "error_log_page_front": {
            "header": {
                "text": "История запусков"
            },
            "columns": {
                "0": "Сделка",
                "1": "Название процесса",
                "2": "Дата запуска",
                "3": "Дата последнего действия",
                "4": "Состояние",
            }
        }
    },
    "en": {
        "error_log_page_front": {
            "header": {
                "text": "История запусков"
            },
            "columns": {
                "0": "Сделка",
                "1": "Название процесса",
                "2": "Дата запуска",
                "3": "Дата последнего действия",
                "4": "Состояние",
            },
        }
    },
    "pt": {
        "error_log_page_front": {
            "header": {
                "text": "История запусков"
            },
            "columns": {
                "0": "Сделка",
                "1": "Название процесса",
                "2": "Дата запуска",
                "3": "Дата последнего действия",
                "4": "Состояние",
            }
        }
    },
    "es": {
        "error_log_page_front": {
            "header": {
                "text": "История запусков"
            },
            "columns": {
                "0": "Сделка",
                "1": "Название процесса",
                "2": "Дата запуска",
                "3": "Дата последнего действия",
                "4": "Состояние",
            }
        }
    }
}