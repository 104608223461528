import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable, Subscription, take } from 'rxjs';
import { HttpHistoryInstanceService } from './http-history-instance.service';
import { IStandartResponse } from 'src/app/services/handle-error.service';
import { GET_LEADS_MESSAGE_KEY, LEADS_RESPONSE_MESSAGE_KEY, TFiltersHistoryPage, THistoryInstance, THistoryInstanceLeadsResponseMessage, THistoryInstanceResponse } from './data-history-instance.types';
import { HelperFunctions } from 'src/app/helpers/HelperFunctions';
import { PostMessageService } from 'src/app/services/contentServices/PostMessage/post-message.service';
import { AmoTableBodyRow } from '../../amo-table/models/amo-table-body-row.model';
import { EnvService } from 'src/app/models/env';
import { DataHistoryPageProcessesService } from '../processes/data-history-page-processes.service';
import * as moment from 'moment';
import { LanguageService } from 'src/app/translate/language.service';
import { AmoTableFilterService } from '../../amo-table/services/filter/amo-table-filter.service';
import { AmoTableService } from '../../amo-table/services/amo-table.service';
import { AmoOverlayService } from 'src/app/htmlElements/amo/amo-overlay/amo-overlay.service';

@Injectable({
    providedIn: 'root'
})
export class DataHistoryInstanceService {
    private historyInstanceSubject = new BehaviorSubject<AmoTableBodyRow[]>(undefined);

    constructor(
        private httpHistoryInstanceService: HttpHistoryInstanceService,
        private postMessageService: PostMessageService,
        private envService: EnvService,
        private dataHistoryPageProcessesService: DataHistoryPageProcessesService,
        private amoTableFilterService: AmoTableFilterService<TFiltersHistoryPage>,
        private amoTableService: AmoTableService,
        private amoOverlayService: AmoOverlayService,
    ) {
        this.amoTableFilterService.getChangeFilter().subscribe((data: any) => {
            !!data && this.getHistoryInstanceData();
        });
    }

    getHistoryInstanceSubject(): Observable<AmoTableBodyRow[]> {
        return this.historyInstanceSubject.asObservable();
    }

    getUrlToLead(id: number): string {
        return `${this.postMessageService.getUsePostOrigin()}/leads/detail/${id}`;
    }

    getUrlToProcess(id: number): string {
        return `${this.envService.getConstructorHost()}constructor/${this.envService.amoId}/${id}?preview_mode=${id}`;
    }

    getProcessName(id: number): string {
        if (this.dataHistoryPageProcessesService.getProcesses()[id]) {
            return this.dataHistoryPageProcessesService.getProcesses()[id];
        }
        return 'process not found';
    }

    formatDateWithTimestamp(dateFormat: string, timestamp: number): string {
        return moment(timestamp*1000).format(dateFormat);
    }

    getHistoryInstanceData(): void {
        this.amoOverlayService.show();
        this.amoTableService.hide();
        this.httpHistoryInstanceService.getHistoryInstanceData(this.amoTableFilterService.getFilter()).pipe(take(1)).subscribe({
            next: (data: IStandartResponse<THistoryInstanceResponse>) => {
                console.log(data.data);
                this.amoTableFilterService.setPagesQuantity(data.data.page_count);
                let ids: number[] = [];
                (data.data.items || []).forEach((value: THistoryInstance) => {
                    ids.push(value.id_lead);
                });
                ids = HelperFunctions.uniqueArr(ids);
                this.postMessageService.sendMessage([{
                    key: GET_LEADS_MESSAGE_KEY,
                    ids: ids
                }]);
                let subscribe: Subscription = this.postMessageService.getPostMessageEvent().pipe(
                    filter((event: MessageEvent<any>) => event?.data?.key === LEADS_RESPONSE_MESSAGE_KEY)
                ).subscribe((event: MessageEvent<THistoryInstanceLeadsResponseMessage>) => {
                    let eventData: THistoryInstanceLeadsResponseMessage = event.data || {
                        key: LEADS_RESPONSE_MESSAGE_KEY,
                        leads: {}
                    };
                    let instances: AmoTableBodyRow[] = [];

                    let getLeadNames = (id: number): string => {
                        if (eventData.leads[id]) {
                            return eventData.leads[id];
                        }
                        return LanguageService.translate(`error_log_page_front.lead_not_found`, {'id': id});
                    }
                    (data.data.items || []).forEach((value: THistoryInstance, index: number) => {
                        let row: AmoTableBodyRow = new AmoTableBodyRow(String(value.id_process_instance),[
                            [getLeadNames(value.id_lead), this.getUrlToLead(value.id_lead)],
                            [this.getProcessName(value.id_process), this.getUrlToProcess(value.id_process_instance)],
                            this.formatDateWithTimestamp('DD.MM.YYYY HH:mm', value.time_start),
                            this.formatDateWithTimestamp('DD.MM.YYYY HH:mm', value.time_last_event),
                            String(value.current_item_id),
                            LanguageService.translate(`error_log_page_front.statuses.${value.state}`)
                        ])
                        instances.push(row);
                    });
                    
                    this.historyInstanceSubject.next(instances);
                    this.amoOverlayService.hide();
                    subscribe.unsubscribe();
                });
            },
            error(err: any) {
                console.log('Error getting history instance data', err);
            }
        });
    }
}
