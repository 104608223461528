import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { langData } from '../error-log-page.lang';
import { LanguageService } from 'src/app/translate/language.service';
import { inject } from '@angular/core';
import { PostMessageService } from 'src/app/services/contentServices/PostMessage/post-message.service';
import { combineLatest, filter, first, mergeMap, of, Subscription, tap } from 'rxjs';

export const errorLogPageGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state) => {
    let languageService: LanguageService = inject(LanguageService);
    let postMessageService: PostMessageService = inject(PostMessageService);
    postMessageService.enablePostMessageEvent();
    languageService.setLangData(langData[languageService.getLang()]);
    if (!!route.queryParams['use_post_origin']) {
        postMessageService.getPostMessageEvent().subscribe((message: MessageEvent) => {
            if (message.origin !== route.queryParams['use_post_origin']) {
                return;
            }
            console.log(message);
        });
        window.parent.postMessage("ready", route.queryParams['use_post_origin']);
    }
    // return combineLatest([
    //     postMessageService.getPostMessageEvent(),
    // //     toObservable(hooksStore.isLoaded),
    // //     toObservable(groupsStore.isLoaded),
    // //     toObservable(scriptsStore.isLoaded),
    // ]).pipe(
    //     filter(([message]: [message: MessageEvent]) => {
    //         return (message.data['processes'] && message.data['processes'].length > 0);
    //     }),
    //     first(),
    //     tap(() => {
    // //         globalEventsService.disableOnlyThisMode();
    // //         keydownSubscription.unsubscribe();
    // //         patchState(environmentStore, { isDataLoaded: true });
    //     }),
    //     mergeMap(() => {
    //         return of(true);
    //     })
    // )
    return true;
};
